import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;
  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"  viewBox="0 0 1277.000000 1280.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd"  transform="translate(0.000000,1280.000000) scale(0.100000,-0.100000)">
		<path d="M9446 11997 c3 -10 27 -17 71 -22 37 -4 61 -10 54 -15 -16 -9 35 -20
98 -20 40 0 94 -17 66 -21 -19 -2 70 -18 106 -18 22 -1 39 -7 42 -15 3 -8 27
-15 62 -18 31 -3 60 -10 64 -16 4 -6 26 -14 50 -18 23 -4 45 -12 47 -19 3 -7
27 -16 55 -19 33 -5 49 -11 49 -21 0 -10 11 -15 34 -15 19 0 41 -6 49 -14 8
-8 30 -18 50 -21 20 -4 38 -13 41 -21 3 -8 16 -14 28 -14 26 0 50 -13 50 -26
-1 -5 15 -13 35 -19 19 -5 32 -13 29 -16 -4 -4 7 -9 24 -13 17 -3 29 -10 26
-15 -4 -5 8 -12 25 -16 17 -4 33 -13 36 -20 2 -8 18 -17 35 -20 16 -4 32 -13
35 -21 3 -8 10 -14 15 -14 14 0 68 -52 63 -61 -3 -3 8 -9 24 -12 31 -7 81 -47
81 -66 0 -6 6 -11 14 -11 8 0 16 -7 20 -15 3 -8 12 -15 21 -15 8 0 15 -9 15
-20 0 -13 7 -20 20 -20 11 0 20 -7 20 -15 0 -8 7 -15 15 -15 8 0 15 -9 15 -20
0 -11 7 -20 15 -20 9 0 19 -8 22 -17 4 -9 15 -25 25 -35 10 -10 18 -27 18 -39
0 -11 4 -18 8 -15 17 10 77 -75 96 -137 3 -10 10 -16 15 -13 10 7 21 -16 21
-45 0 -10 6 -19 14 -19 7 0 16 -14 20 -31 3 -18 13 -37 21 -43 8 -6 16 -24 18
-39 2 -16 9 -32 15 -36 7 -4 15 -31 19 -59 3 -31 11 -52 18 -52 7 0 15 -20 19
-46 3 -25 13 -52 21 -60 10 -11 15 -36 15 -80 0 -47 4 -64 14 -64 17 0 26 -69
27 -220 0 -74 3 -108 9 -95 5 12 9 -33 9 -120 0 -78 -4 -131 -8 -120 -5 11
-10 -57 -13 -160 -6 -177 -11 -220 -29 -209 -5 3 -9 -27 -9 -68 0 -79 -8 -121
-20 -113 -5 3 -11 -19 -15 -47 -10 -83 -16 -109 -24 -103 -5 2 -11 -21 -14
-52 -3 -31 -10 -59 -16 -62 -5 -3 -12 -26 -16 -51 -8 -58 -15 -84 -27 -91 -5
-3 -12 -26 -15 -50 -3 -24 -12 -48 -19 -52 -8 -4 -14 -21 -14 -37 0 -16 -7
-36 -16 -45 -9 -9 -19 -36 -22 -61 -3 -28 -10 -44 -18 -44 -9 0 -14 -12 -14
-35 0 -22 -6 -38 -15 -41 -8 -3 -17 -21 -21 -40 -3 -19 -12 -34 -19 -34 -7 0
-15 -20 -19 -50 -5 -34 -11 -50 -21 -50 -10 0 -15 -10 -15 -30 0 -19 -7 -33
-20 -40 -13 -7 -20 -21 -20 -41 0 -17 -6 -32 -15 -35 -9 -4 -15 -19 -15 -36 0
-21 -6 -31 -20 -35 -15 -4 -20 -14 -20 -39 0 -23 -5 -34 -15 -34 -8 0 -17 -14
-21 -35 -3 -19 -12 -35 -19 -35 -7 0 -16 -16 -19 -35 -4 -20 -11 -33 -17 -30
-5 4 -9 -6 -9 -20 0 -18 -6 -29 -19 -32 -14 -3 -21 -15 -23 -37 -2 -19 -8 -31
-18 -31 -9 0 -17 -13 -20 -35 -3 -20 -11 -34 -18 -33 -7 2 -12 -3 -12 -10 0
-8 -9 -24 -20 -37 -11 -13 -20 -31 -20 -42 0 -10 -6 -18 -14 -18 -8 0 -17 -14
-21 -31 -4 -17 -13 -33 -21 -36 -8 -3 -14 -13 -14 -23 0 -10 -9 -20 -20 -23
-11 -3 -20 -9 -19 -14 5 -27 -3 -53 -16 -53 -8 0 -15 -8 -15 -19 0 -10 -9 -21
-19 -24 -11 -3 -22 -17 -26 -36 -4 -17 -11 -29 -16 -26 -4 3 -11 -6 -15 -20
-3 -14 -12 -25 -19 -25 -8 0 -16 -13 -20 -30 -4 -16 -13 -30 -21 -30 -8 0 -14
-9 -14 -20 0 -11 -7 -23 -15 -26 -8 -4 -15 -11 -15 -18 0 -6 -9 -19 -20 -29
-11 -9 -20 -25 -20 -36 0 -11 -9 -21 -21 -24 -12 -3 -18 -10 -14 -17 4 -7 -1
-13 -11 -16 -11 -3 -25 -16 -32 -30 -6 -13 -18 -35 -24 -49 -7 -14 -19 -25
-25 -25 -7 0 -13 -9 -13 -20 0 -12 -7 -20 -16 -20 -9 0 -18 -9 -21 -20 -3 -11
-11 -20 -17 -20 -7 0 -18 -16 -25 -35 -7 -19 -17 -35 -22 -35 -5 0 -9 -7 -9
-15 0 -8 -7 -15 -16 -15 -9 0 -18 -9 -21 -20 -3 -11 -11 -20 -18 -20 -7 0 -15
-9 -18 -20 -3 -11 -12 -20 -21 -20 -9 0 -16 -8 -16 -20 0 -11 -6 -20 -14 -20
-8 0 -17 -14 -21 -30 -4 -17 -13 -30 -21 -30 -8 0 -14 -9 -14 -20 0 -12 -7
-20 -16 -20 -9 0 -18 -9 -21 -20 -3 -11 -11 -20 -18 -20 -7 0 -15 -7 -19 -15
-3 -8 -12 -15 -21 -15 -8 0 -15 -9 -15 -20 0 -11 -6 -20 -14 -20 -7 0 -16 -9
-19 -20 -3 -11 -12 -20 -20 -20 -8 0 -17 -9 -20 -21 -3 -12 -10 -18 -15 -15
-5 3 -12 -3 -15 -14 -3 -11 -12 -20 -21 -20 -9 0 -16 -6 -16 -13 0 -7 -8 -20
-17 -28 -29 -25 -63 -72 -63 -86 0 -7 -7 -13 -15 -13 -9 0 -18 -7 -21 -15 -4
-8 -12 -15 -20 -15 -8 0 -14 -9 -14 -20 0 -12 -7 -20 -16 -20 -9 0 -18 -9 -21
-20 -3 -11 -12 -20 -19 -20 -8 0 -14 -7 -14 -15 0 -8 -7 -15 -16 -15 -9 0 -18
-9 -21 -20 -3 -11 -14 -20 -24 -20 -11 0 -19 -6 -19 -14 0 -8 -7 -16 -15 -20
-8 -3 -15 -12 -15 -21 0 -8 -7 -15 -15 -15 -9 0 -18 -6 -21 -14 -3 -8 -18 -19
-34 -26 -16 -7 -31 -18 -34 -26 -3 -8 -12 -14 -21 -14 -8 0 -15 -6 -15 -12 0
-15 -43 -58 -58 -58 -5 0 -12 -9 -15 -20 -3 -11 -13 -20 -22 -20 -9 0 -14 -4
-10 -9 5 -10 -12 -25 -30 -26 -5 -1 -9 -6 -8 -12 2 -7 -6 -15 -17 -18 -11 -4
-20 -13 -20 -21 0 -8 -7 -14 -16 -14 -9 0 -18 -9 -21 -20 -3 -11 -14 -20 -24
-20 -11 0 -19 -6 -19 -14 0 -12 -34 -30 -52 -27 -5 1 -8 -6 -8 -14 0 -8 -6
-15 -14 -15 -8 0 -18 -9 -21 -20 -3 -11 -15 -20 -26 -20 -10 0 -19 -7 -19 -15
0 -8 -7 -15 -15 -15 -8 0 -15 -7 -15 -15 0 -9 -11 -20 -25 -25 -14 -5 -25 -14
-25 -19 0 -5 -13 -12 -30 -16 -16 -4 -30 -13 -30 -21 0 -8 -6 -14 -14 -14 -7
0 -16 -9 -19 -20 -3 -11 -12 -20 -21 -20 -9 0 -16 -7 -16 -15 0 -8 -9 -15 -20
-15 -11 0 -20 -6 -20 -14 0 -12 -44 -32 -62 -28 -5 1 -8 -5 -8 -13 0 -8 -7
-15 -16 -15 -9 0 -18 -9 -21 -20 -3 -11 -12 -20 -19 -20 -8 0 -17 -6 -20 -14
-3 -8 -18 -18 -34 -21 -17 -4 -30 -14 -33 -27 -3 -11 -10 -17 -16 -14 -5 4
-15 -3 -21 -14 -6 -11 -17 -20 -25 -20 -8 0 -15 -4 -15 -9 0 -5 -15 -15 -34
-22 -19 -7 -34 -17 -33 -23 1 -6 -8 -12 -20 -14 -13 -2 -23 -9 -23 -17 0 -7
-13 -16 -29 -20 -16 -3 -32 -13 -35 -21 -3 -8 -12 -14 -21 -14 -8 0 -15 -6
-15 -14 0 -8 -15 -17 -34 -20 -22 -4 -36 -13 -39 -26 -3 -11 -12 -20 -21 -20
-9 0 -16 -7 -16 -15 0 -8 -8 -15 -19 -15 -10 0 -30 -9 -44 -20 -14 -11 -30
-20 -35 -20 -5 0 -12 -7 -16 -15 -3 -9 -18 -15 -36 -15 -23 0 -30 -4 -30 -20
0 -13 -7 -20 -20 -20 -11 0 -20 -6 -20 -14 0 -8 -13 -17 -29 -21 -16 -3 -32
-13 -35 -21 -3 -8 -15 -14 -26 -14 -11 0 -20 -7 -20 -15 0 -8 -14 -17 -35 -21
-19 -3 -33 -11 -32 -16 1 -6 -11 -14 -27 -19 -17 -5 -34 -15 -38 -24 -5 -8
-18 -15 -28 -15 -11 0 -20 -7 -20 -15 0 -8 -14 -17 -35 -21 -20 -3 -35 -12
-35 -20 0 -12 -27 -19 -46 -11 -4 1 -10 -8 -15 -20 -6 -16 -17 -23 -34 -23
-15 0 -24 -4 -21 -9 5 -8 -18 -20 -49 -25 -5 -1 -9 -7 -8 -14 2 -6 -13 -14
-33 -18 -20 -4 -38 -12 -40 -19 -3 -7 -22 -16 -42 -20 -25 -5 -31 -9 -22 -15
9 -6 1 -12 -28 -20 -23 -6 -42 -15 -42 -19 0 -5 -13 -14 -30 -20 -16 -5 -30
-15 -30 -20 0 -6 -11 -11 -25 -11 -14 0 -28 -6 -31 -14 -3 -8 -20 -18 -37 -22
-18 -3 -39 -12 -47 -19 -8 -7 -25 -16 -37 -19 -13 -4 -23 -11 -23 -17 0 -5
-18 -12 -40 -15 -23 -4 -40 -12 -40 -20 0 -7 -16 -16 -35 -20 -19 -3 -35 -12
-35 -19 0 -7 -16 -16 -35 -19 -19 -4 -35 -11 -35 -15 0 -5 -15 -12 -34 -15
-19 -4 -36 -12 -38 -18 -2 -6 -11 -13 -19 -14 -62 -12 -75 -17 -82 -30 -4 -8
-16 -14 -26 -14 -10 0 -28 -9 -41 -20 -13 -11 -35 -20 -50 -20 -16 0 -30 -7
-34 -15 -3 -9 -19 -15 -41 -15 -22 0 -34 -4 -30 -10 8 -12 -36 -30 -76 -30
-18 0 -29 -5 -29 -14 0 -8 -15 -17 -34 -20 -19 -4 -37 -13 -40 -21 -3 -9 -19
-15 -41 -15 -24 0 -35 -4 -35 -15 0 -10 -10 -15 -27 -15 -43 0 -73 -11 -73
-26 0 -10 -13 -14 -40 -14 -25 0 -42 -6 -48 -15 -4 -9 -26 -18 -48 -22 -21 -3
-43 -12 -49 -19 -5 -6 -31 -15 -57 -19 -28 -3 -47 -11 -45 -18 1 -6 -20 -14
-53 -18 -30 -4 -56 -12 -58 -18 -2 -6 -13 -11 -25 -11 -12 0 -39 -9 -60 -20
-20 -11 -55 -20 -77 -20 -29 0 -42 -5 -50 -20 -6 -11 -14 -19 -18 -19 -33 5
-102 -3 -97 -11 9 -15 -35 -28 -98 -29 -16 -1 -26 -5 -23 -10 7 -12 -28 -21
-82 -21 -28 0 -45 -5 -48 -14 -4 -10 -30 -17 -80 -21 -40 -4 -72 -11 -69 -15
6 -9 -20 -15 -105 -26 -30 -3 -58 -11 -62 -17 -4 -6 -37 -13 -75 -15 -37 -3
-77 -10 -88 -17 -11 -7 -60 -16 -110 -19 -52 -4 -92 -11 -97 -18 -4 -8 -45
-14 -115 -16 -119 -3 -156 -8 -149 -21 7 -10 -143 -20 -329 -23 -98 -1 -122
-3 -80 -7 53 -5 44 -7 -66 -13 -144 -8 -361 2 -274 13 32 4 2 6 -85 7 -198 3
-299 12 -294 27 4 10 -22 14 -106 17 -82 2 -113 7 -120 17 -5 9 -32 16 -72 19
-35 3 -72 11 -83 18 -11 8 -33 14 -50 15 -30 0 -79 16 -60 19 20 3 -31 19 -62
19 -22 1 -33 6 -33 16 0 9 -9 15 -24 15 -45 0 -86 13 -86 26 0 9 -11 14 -29
14 -16 0 -36 7 -45 15 -8 8 -21 15 -29 15 -26 0 -47 12 -47 26 0 9 -11 14 -28
14 -15 0 -35 9 -45 20 -9 11 -26 20 -37 20 -11 0 -23 6 -26 14 -3 8 -19 18
-35 21 -16 4 -29 11 -29 16 0 5 -11 14 -25 19 -14 5 -25 14 -25 19 0 5 -13 14
-30 20 -16 5 -30 15 -30 20 0 6 -7 11 -15 11 -8 0 -19 9 -25 20 -6 11 -17 20
-25 20 -8 0 -15 6 -15 14 0 8 -9 18 -20 21 -11 3 -20 12 -20 19 0 8 -13 16
-30 20 -16 3 -30 12 -30 20 0 7 -9 16 -20 19 -11 3 -20 12 -20 21 0 9 -7 16
-15 16 -8 0 -15 7 -15 15 0 8 -9 15 -19 15 -13 0 -21 9 -26 29 -3 16 -13 32
-21 35 -8 3 -14 12 -14 21 0 8 -6 15 -14 15 -8 0 -18 9 -21 20 -3 11 -13 20
-21 20 -8 0 -14 7 -14 15 0 8 -9 19 -20 25 -11 6 -19 14 -18 18 3 14 -17 47
-44 74 -15 15 -28 34 -28 43 0 8 -9 23 -20 32 -11 10 -20 25 -20 35 0 10 -7
18 -15 18 -10 0 -15 10 -15 31 0 22 -5 32 -19 36 -11 3 -22 14 -24 25 -7 24
-27 15 -22 -10 2 -9 4 -32 4 -49 1 -22 6 -33 16 -33 11 0 15 -11 15 -40 0 -27
4 -40 14 -40 9 0 16 -18 20 -50 5 -34 11 -50 21 -50 10 0 15 -11 15 -34 0 -25
5 -35 20 -39 15 -4 20 -14 20 -36 0 -21 5 -31 15 -31 8 0 15 -7 15 -16 0 -9 9
-27 20 -41 11 -14 20 -31 20 -38 0 -7 6 -15 14 -18 8 -3 17 -19 21 -36 4 -17
13 -31 21 -31 8 0 14 -9 14 -20 0 -13 7 -20 20 -20 13 0 20 -7 20 -20 0 -11 4
-20 9 -20 9 0 24 -38 22 -53 -1 -5 8 -11 19 -14 11 -3 20 -11 20 -18 0 -7 9
-15 20 -18 11 -3 20 -12 20 -21 0 -9 7 -16 15 -16 8 0 15 -4 15 -9 0 -14 45
-61 59 -61 6 0 11 -9 11 -20 0 -13 7 -20 20 -20 11 0 20 -6 20 -14 0 -7 9 -16
20 -19 11 -3 20 -13 20 -22 0 -9 5 -13 11 -10 6 4 13 -2 16 -13 3 -13 17 -23
34 -27 16 -4 29 -13 29 -21 0 -8 9 -14 20 -14 11 0 23 -7 26 -15 4 -8 12 -15
20 -15 8 0 14 -8 14 -19 0 -13 10 -21 34 -25 19 -4 36 -13 39 -20 3 -8 19 -17
36 -21 17 -4 31 -11 31 -16 0 -5 11 -14 25 -19 14 -5 25 -14 25 -19 0 -5 14
-13 32 -16 17 -4 38 -14 46 -21 7 -8 22 -14 32 -14 11 0 23 -6 27 -13 4 -8 26
-17 47 -20 22 -4 44 -13 48 -22 5 -8 22 -15 38 -15 16 0 33 -7 38 -16 5 -9 28
-18 53 -21 24 -3 49 -11 54 -18 6 -6 32 -14 59 -17 27 -3 52 -11 55 -17 4 -6
36 -15 71 -19 35 -5 66 -14 68 -21 2 -6 20 -11 40 -11 76 0 142 -13 142 -26 0
-11 23 -14 103 -14 122 -1 211 -9 215 -21 6 -14 709 -11 735 3 12 6 91 13 189
16 111 3 166 8 165 15 -3 14 68 25 164 26 59 1 78 5 83 16 4 11 21 15 65 15
37 0 75 7 102 20 27 12 66 20 100 20 38 0 61 5 69 15 8 10 31 15 69 15 70 0
101 8 101 26 0 10 15 14 54 14 35 0 60 5 69 15 9 8 40 18 71 21 35 4 56 12 56
19 0 8 22 14 66 17 36 2 69 9 73 16 4 7 31 15 59 19 31 3 52 11 52 18 0 7 21
15 52 18 31 4 55 13 60 22 6 10 25 15 58 15 36 0 50 4 50 14 0 13 41 26 86 26
14 0 24 6 24 14 0 9 16 17 43 21 23 4 45 12 48 17 3 5 25 12 48 15 22 3 41 9
41 14 0 4 25 13 55 19 31 6 54 16 52 23 -1 7 18 14 47 17 30 4 52 12 58 23 5
10 21 17 39 17 17 0 32 6 35 15 4 10 19 15 43 15 24 0 42 7 54 20 10 12 29 20
47 20 19 0 30 5 30 14 0 9 15 17 39 21 22 4 42 13 45 21 3 8 19 14 36 14 16 0
30 3 30 8 0 10 50 32 76 32 12 0 24 7 28 15 3 9 18 15 35 15 20 0 34 7 41 20
7 13 21 20 40 20 19 0 30 5 30 14 0 8 15 17 34 20 19 4 36 13 39 21 3 7 21 15
39 17 18 2 32 8 31 15 -1 6 15 14 36 18 22 4 42 13 45 21 3 8 19 14 36 14 20
0 30 5 30 15 0 8 14 17 35 21 19 3 35 12 35 19 0 7 16 16 35 19 19 4 35 11 35
16 0 5 16 12 35 16 19 3 35 12 35 19 0 8 17 16 40 20 22 4 38 11 37 17 -1 6
11 14 27 19 17 5 34 15 38 24 5 8 18 15 28 15 11 0 20 5 20 11 0 5 4 7 10 4 6
-3 10 -1 10 4 0 6 9 11 20 11 11 0 23 7 26 15 3 8 21 17 40 21 19 3 34 12 34
20 0 7 16 16 35 20 20 3 35 12 35 20 0 8 8 14 19 14 10 0 30 9 44 20 14 11 30
20 35 20 5 0 12 6 15 14 3 8 19 17 36 21 17 4 31 13 31 21 0 9 11 14 29 14 20
0 30 6 34 20 3 14 14 20 32 20 15 0 24 4 20 10 -7 11 35 31 48 23 4 -2 7 3 7
11 0 9 11 18 25 22 14 3 25 10 25 15 0 5 6 9 14 9 8 0 24 11 37 25 13 13 32
25 42 25 9 0 25 9 34 20 10 11 25 20 35 20 10 0 18 7 18 15 0 8 9 15 20 15 11
0 28 9 37 20 10 11 23 20 30 20 7 0 13 7 13 15 0 8 9 15 19 15 30 0 51 11 51
26 0 8 9 14 20 14 11 0 20 6 20 14 0 8 15 17 35 20 19 4 34 12 32 19 -1 6 8
13 19 15 12 2 24 12 27 23 3 13 14 19 31 19 17 0 26 5 26 16 0 9 9 18 20 21
11 3 20 9 20 14 0 5 11 14 25 19 14 5 25 14 25 19 0 5 14 12 30 16 17 4 30 13
30 21 0 8 7 14 16 14 9 0 18 9 21 20 5 17 27 26 56 21 4 0 7 5 7 13 0 8 9 17
19 21 11 3 22 12 25 20 3 8 10 15 16 15 5 0 19 8 29 17 37 34 51 43 76 53 15
6 23 14 20 20 -3 6 5 14 19 20 14 5 26 14 26 20 0 5 9 10 20 10 11 0 20 6 20
14 0 8 11 17 25 20 13 3 29 15 35 26 6 11 17 20 25 20 8 0 15 7 15 15 0 8 7
15 15 15 9 0 18 7 21 15 4 8 13 15 20 15 8 0 18 8 22 18 7 16 22 24 50 25 6 0
12 9 12 19 0 10 7 18 16 18 9 0 18 9 21 20 3 11 13 20 23 20 10 0 20 9 23 21
3 12 10 18 16 14 6 -3 11 1 11 9 0 9 7 16 16 16 9 0 18 9 21 20 3 11 12 20 19
20 8 0 17 6 20 14 5 13 23 21 49 24 5 0 12 10 17 21 4 12 14 21 23 21 8 0 15
7 15 15 0 8 7 15 16 15 9 0 18 9 21 20 3 11 12 20 19 20 8 0 14 6 14 14 0 8 9
18 20 21 11 3 20 13 20 21 0 8 6 14 14 14 8 0 16 7 20 15 3 8 12 15 21 15 8 0
15 9 15 20 0 13 7 20 20 20 11 0 20 7 20 15 0 8 7 15 16 15 9 0 18 9 21 20 3
11 10 20 15 20 9 0 23 11 79 63 10 9 24 17 29 17 6 0 10 7 10 15 0 8 9 15 20
15 11 0 20 7 20 15 0 7 6 18 13 22 8 4 16 16 20 26 3 9 13 17 21 17 7 0 16 7
20 15 3 8 12 15 21 15 8 0 15 7 15 15 0 8 9 19 20 25 11 6 20 15 20 20 0 11
26 45 53 69 9 8 17 21 17 28 0 7 7 13 16 13 9 0 18 9 21 20 3 11 12 20 19 20
8 0 14 5 14 11 0 14 47 59 61 59 5 0 9 9 9 20 0 12 7 20 16 20 9 0 18 9 21 20
3 11 11 20 18 20 7 0 15 9 18 20 3 11 12 20 21 20 9 0 16 6 16 13 0 7 9 20 20
30 11 9 20 24 20 32 0 8 5 15 10 15 6 0 15 11 20 25 5 14 16 25 25 25 8 0 15
9 15 20 0 11 4 20 9 20 14 0 61 45 61 59 0 6 6 11 14 11 8 0 17 13 21 29 3 16
13 32 21 35 8 3 14 12 14 21 0 8 7 15 16 15 9 0 18 9 21 20 3 11 12 20 19 20
8 0 14 9 14 20 0 11 6 20 14 20 8 0 17 14 21 31 4 19 15 33 26 36 10 3 19 12
19 19 0 8 5 14 10 14 6 0 15 11 20 25 5 14 14 25 19 25 5 0 12 14 16 30 4 17
13 30 21 30 8 0 14 7 14 16 0 9 9 18 20 21 11 3 20 14 20 24 0 10 9 27 21 38
12 11 18 25 14 31 -4 7 1 14 13 17 13 3 23 17 27 34 4 16 11 29 16 29 5 0 14
11 19 25 5 14 14 25 19 25 5 0 12 14 16 30 4 17 13 30 21 30 8 0 14 9 14 20 0
11 7 20 15 20 8 0 17 14 21 34 3 19 12 37 19 39 6 3 15 19 18 37 4 17 11 29
16 26 5 -3 14 8 20 24 5 17 14 30 20 30 5 0 12 13 16 29 3 16 12 32 20 34 7 3
16 19 20 36 4 17 11 31 16 31 5 0 9 9 9 20 0 11 7 23 15 26 8 4 12 10 9 15 -3
5 0 9 5 9 6 0 11 8 11 18 0 10 9 25 20 35 11 9 20 26 20 37 0 11 6 23 14 26 8
3 18 20 22 37 3 17 13 37 20 45 8 7 14 24 14 38 0 14 6 24 14 24 9 0 17 15 21
40 4 22 12 38 18 37 7 -1 13 13 15 31 2 18 10 36 18 39 8 3 17 23 20 45 4 22
12 42 19 44 7 3 15 22 19 44 3 21 12 40 20 43 8 3 16 21 18 39 2 18 8 32 14
31 7 -1 14 21 17 53 3 31 12 61 21 67 8 7 17 32 20 57 3 25 5 46 5 48 1 2 5 1
10 -2 10 -7 21 27 21 65 0 15 7 29 15 33 11 4 15 20 15 55 0 39 4 53 18 60 15
8 19 24 21 73 1 42 7 66 17 71 8 5 14 25 15 50 2 59 12 102 24 99 6 -1 13 40
16 99 5 69 11 104 20 110 21 12 21 816 0 828 -9 6 -16 38 -20 92 -4 56 -10 83
-18 82 -7 -2 -14 19 -19 55 -4 31 -11 60 -16 63 -6 4 -13 27 -15 53 -4 27 -11
46 -18 46 -14 0 -25 31 -25 73 0 17 -5 27 -15 27 -9 0 -15 9 -15 25 0 14 -9
33 -20 42 -11 10 -20 23 -20 30 0 7 -9 22 -20 33 -11 11 -20 29 -20 39 0 10
-6 21 -14 24 -8 3 -17 19 -21 36 -3 17 -12 29 -18 28 -6 -1 -13 8 -15 21 -2
12 -10 22 -18 22 -8 0 -14 9 -14 20 0 11 -9 28 -20 37 -11 10 -20 23 -20 30 0
7 -7 13 -16 13 -9 0 -18 9 -21 20 -3 11 -12 20 -19 20 -8 0 -14 5 -14 11 0 13
-46 59 -59 59 -5 0 -11 9 -14 20 -3 11 -12 20 -21 20 -9 0 -16 7 -16 15 0 8
-7 15 -16 15 -9 0 -18 9 -21 20 -3 11 -11 20 -18 20 -7 0 -15 9 -18 20 -3 11
-12 20 -21 20 -9 0 -16 6 -16 14 0 8 -15 17 -35 20 -20 4 -33 11 -29 17 3 6
-3 13 -15 16 -12 3 -21 12 -21 19 0 8 -9 14 -20 14 -11 0 -28 9 -37 20 -10 11
-25 20 -34 20 -9 0 -19 6 -22 14 -3 8 -19 17 -35 21 -17 3 -33 12 -35 20 -3 7
-20 16 -38 20 -22 5 -28 10 -19 15 9 6 2 11 -23 15 -20 4 -37 13 -37 20 0 7
-18 15 -41 18 -22 3 -48 12 -58 21 -10 9 -27 16 -40 16 -12 0 -24 6 -27 14 -3
8 -23 17 -45 21 -24 4 -39 12 -39 21 0 10 -13 14 -42 14 -37 0 -92 18 -63 21
24 2 -34 18 -67 18 -29 1 -38 5 -38 19 0 14 -2 15 -10 2 -5 -8 -10 -10 -10 -4
0 6 -27 14 -62 18 -34 3 -65 11 -69 18 -4 7 -37 14 -73 16 -44 3 -66 9 -66 17
0 12 -69 25 -138 25 -18 0 -43 6 -55 14 -14 9 -65 16 -140 19 -76 4 -124 10
-134 19 -12 9 -64 14 -190 16 -150 4 -172 2 -167 -11z"/>
<path d="M5785 11863 c-16 -3 -34 -11 -38 -19 -4 -8 -22 -14 -38 -14 -22 0
-32 -6 -36 -20 -3 -11 -14 -20 -24 -20 -11 0 -19 -6 -19 -14 0 -8 -13 -17 -29
-21 -17 -4 -31 -14 -34 -26 -3 -11 -11 -18 -18 -17 -7 2 -17 -12 -22 -29 -5
-18 -14 -33 -18 -33 -5 0 -9 -7 -9 -15 0 -7 -6 -18 -14 -22 -7 -4 -16 -29 -20
-56 -6 -38 -4 -48 8 -53 9 -3 16 -10 16 -15 0 -5 10 -19 21 -30 29 -29 105
-32 130 -6 10 11 22 16 28 13 6 -4 19 2 29 12 10 10 26 21 35 25 9 3 17 13 17
22 0 10 10 15 30 15 17 0 33 6 36 14 3 8 14 16 25 19 10 3 19 12 19 21 0 11 9
16 28 16 36 0 40 -5 35 -35 -3 -15 -13 -25 -27 -27 -11 -2 -21 -8 -21 -15 0
-16 -8 -22 -36 -26 -13 -2 -24 -10 -24 -18 0 -8 -12 -15 -27 -17 -19 -2 -25
-8 -21 -18 5 -14 -28 -20 -81 -16 -12 2 -16 -3 -13 -12 4 -11 -11 -15 -69 -21
-41 -4 -74 -11 -74 -17 0 -13 47 -58 61 -58 5 0 9 -9 9 -20 0 -13 7 -20 20
-20 11 0 20 -6 20 -14 0 -8 7 -16 15 -20 8 -3 15 -12 15 -21 0 -8 7 -15 16
-15 9 0 18 -9 21 -20 3 -11 11 -20 18 -20 7 0 15 -9 18 -20 3 -11 12 -20 21
-20 9 0 16 -5 16 -12 0 -6 18 -28 40 -49 32 -31 40 -45 40 -75 0 -27 -5 -37
-19 -41 -11 -3 -22 -11 -25 -18 -2 -7 -18 -16 -35 -20 -17 -4 -31 -10 -31 -15
0 -5 -13 -11 -29 -15 -16 -3 -32 -13 -35 -21 -3 -8 -15 -14 -26 -14 -11 0 -23
-7 -26 -15 -4 -8 -11 -15 -18 -15 -6 0 -28 -9 -49 -20 -20 -11 -44 -20 -52
-20 -9 0 -18 -7 -21 -15 -3 -8 -21 -20 -40 -27 -19 -6 -34 -15 -34 -18 0 -4
-16 -12 -35 -20 -19 -8 -35 -17 -35 -22 0 -4 -6 -8 -13 -8 -8 0 -24 -9 -37
-20 -13 -11 -31 -20 -42 -20 -10 0 -18 -6 -18 -14 0 -8 -15 -17 -35 -20 -21
-4 -35 -13 -35 -21 0 -8 -8 -15 -18 -15 -11 0 -29 -9 -42 -20 -13 -11 -27 -20
-32 -20 -4 0 -17 -9 -28 -20 -11 -11 -29 -20 -41 -20 -11 0 -17 -4 -14 -10 3
-5 -6 -12 -20 -16 -14 -3 -25 -10 -25 -14 0 -5 -16 -14 -35 -21 -19 -7 -35
-19 -35 -26 0 -7 -9 -13 -20 -13 -11 0 -28 -9 -37 -20 -10 -11 -25 -20 -35
-20 -10 0 -18 -4 -18 -9 0 -5 -13 -14 -30 -21 -16 -7 -30 -16 -30 -21 0 -5
-11 -14 -25 -19 -14 -5 -25 -14 -25 -19 0 -5 -13 -12 -30 -16 -16 -4 -30 -13
-30 -21 0 -8 -9 -14 -19 -14 -10 0 -21 -7 -25 -15 -3 -8 -12 -15 -19 -15 -8 0
-22 -9 -32 -20 -10 -11 -23 -20 -28 -20 -6 0 -17 -7 -26 -15 -35 -33 -79 -65
-90 -65 -6 0 -11 -5 -11 -10 0 -6 -11 -15 -25 -20 -14 -5 -25 -14 -25 -20 0
-5 -6 -10 -14 -10 -8 0 -24 -11 -37 -25 -13 -13 -29 -25 -35 -25 -7 0 -15 -7
-18 -16 -3 -8 -10 -13 -15 -9 -5 3 -12 1 -16 -5 -4 -6 -11 -8 -16 -5 -5 4 -9
-1 -9 -10 0 -9 -9 -19 -20 -22 -11 -3 -20 -9 -20 -14 0 -5 -11 -14 -25 -19
-14 -5 -25 -14 -25 -20 0 -5 -6 -10 -13 -10 -7 0 -20 -8 -29 -17 -29 -32 -63
-59 -80 -66 -10 -3 -18 -11 -18 -17 0 -5 -7 -10 -15 -10 -7 0 -18 -6 -22 -13
-4 -6 -20 -18 -35 -26 -16 -8 -26 -17 -23 -19 9 -10 -17 -31 -31 -25 -8 3 -14
-1 -14 -11 0 -9 -7 -16 -15 -16 -8 0 -19 -9 -25 -20 -6 -11 -15 -20 -20 -20
-5 0 -25 -15 -44 -32 -65 -61 -81 -74 -98 -81 -10 -3 -18 -11 -18 -17 0 -5 -5
-10 -11 -10 -6 0 -36 -25 -67 -55 -31 -30 -60 -55 -64 -55 -4 0 -8 -7 -8 -15
0 -8 -7 -15 -15 -15 -9 0 -32 -17 -53 -38 -20 -21 -45 -42 -54 -45 -10 -4 -18
-10 -18 -16 0 -5 -9 -11 -20 -14 -11 -3 -20 -12 -20 -21 0 -9 -7 -16 -15 -16
-8 0 -15 -7 -15 -15 0 -8 -5 -15 -11 -15 -6 0 -46 -35 -88 -77 -43 -43 -109
-108 -147 -144 -38 -37 -84 -83 -101 -103 -18 -20 -33 -31 -34 -24 0 8 -3 6
-7 -3 -6 -15 -41 -53 -154 -169 -21 -21 -38 -43 -38 -49 0 -6 -7 -11 -15 -11
-8 0 -15 -9 -15 -20 0 -11 -4 -20 -9 -20 -14 0 -56 -42 -64 -63 -4 -10 -11
-15 -17 -12 -5 3 -10 -1 -10 -9 0 -8 -6 -19 -12 -23 -7 -4 -18 -17 -25 -28 -6
-11 -16 -19 -23 -17 -6 1 -9 -2 -5 -8 3 -5 1 -10 -4 -10 -6 0 -11 -7 -11 -15
0 -8 -9 -19 -20 -25 -11 -6 -20 -17 -20 -24 0 -8 -7 -16 -15 -20 -8 -3 -15
-12 -15 -21 0 -8 -6 -15 -12 -15 -7 0 -26 -23 -43 -50 -16 -27 -36 -50 -42
-50 -7 0 -13 -9 -13 -20 0 -11 -6 -20 -14 -20 -8 0 -17 -11 -20 -25 -4 -14
-11 -23 -16 -20 -6 3 -14 -5 -20 -20 -9 -25 -18 -38 -51 -74 -8 -10 -18 -25
-22 -34 -3 -9 -12 -17 -19 -17 -9 0 -8 -3 2 -10 13 -8 12 -10 -2 -10 -11 0
-18 -8 -18 -20 0 -11 -7 -20 -15 -20 -8 0 -15 -7 -15 -15 0 -7 -6 -18 -13 -22
-7 -4 -18 -19 -25 -33 -33 -65 -44 -80 -58 -80 -7 0 -13 -3 -12 -7 4 -14 -13
-53 -22 -53 -9 0 -53 -84 -55 -105 0 -5 -6 -9 -13 -8 -7 2 -12 -5 -12 -16 0
-10 -10 -29 -23 -42 -13 -13 -34 -46 -47 -74 -13 -27 -30 -54 -37 -58 -7 -4
-13 -14 -13 -21 0 -8 -7 -19 -15 -26 -8 -7 -15 -21 -15 -32 0 -10 -6 -21 -14
-24 -8 -3 -18 -19 -21 -36 -4 -16 -13 -32 -21 -35 -7 -3 -16 -20 -20 -39 -3
-19 -12 -34 -20 -34 -8 0 -13 -3 -12 -7 3 -15 -14 -63 -23 -63 -4 0 -11 -15
-15 -34 -3 -19 -13 -37 -21 -40 -8 -3 -12 -10 -8 -15 3 -5 1 -12 -5 -16 -5 -3
-10 -15 -10 -26 0 -10 -4 -19 -9 -19 -4 0 -13 -15 -18 -32 -5 -18 -14 -32 -20
-31 -6 1 -14 -14 -18 -35 -3 -20 -12 -46 -19 -57 -8 -11 -17 -35 -20 -53 -4
-17 -11 -32 -16 -32 -4 0 -11 -18 -15 -39 -4 -22 -12 -42 -19 -44 -7 -3 -16
-24 -19 -48 -3 -23 -10 -45 -15 -48 -5 -3 -13 -22 -17 -41 -4 -19 -13 -44 -20
-55 -7 -11 -16 -44 -20 -73 -4 -28 -11 -52 -15 -52 -5 0 -12 -17 -16 -37 -4
-21 -15 -60 -25 -86 -11 -26 -19 -65 -19 -87 0 -21 -7 -45 -15 -54 -9 -9 -15
-33 -15 -61 0 -52 -18 -133 -33 -153 -7 -9 -11 -129 -11 -335 -1 -282 1 -326
16 -352 9 -17 20 -63 23 -103 4 -40 11 -72 16 -72 4 0 13 -26 20 -57 7 -32 18
-65 26 -74 7 -8 13 -23 13 -32 0 -8 6 -22 14 -29 7 -8 19 -30 27 -51 7 -20 16
-37 20 -37 4 0 10 -15 13 -34 4 -19 13 -37 21 -40 8 -3 15 -12 15 -19 0 -8 9
-22 20 -32 11 -10 20 -26 20 -37 0 -10 7 -18 15 -18 8 0 15 -7 15 -15 0 -8 9
-24 20 -35 11 -11 20 -26 20 -34 0 -7 9 -16 20 -19 11 -3 20 -12 20 -20 0 -8
9 -17 21 -20 12 -3 18 -10 14 -16 -3 -6 1 -11 10 -11 8 0 19 -8 22 -17 7 -19
39 -53 50 -53 5 0 19 -12 32 -25 13 -14 29 -25 37 -25 8 0 14 -7 14 -15 0 -8
4 -15 9 -15 5 0 16 -6 23 -13 21 -20 97 -67 108 -67 5 0 10 -7 10 -15 0 -8 9
-15 20 -15 11 0 28 -9 37 -20 10 -11 25 -20 35 -20 10 0 18 -4 18 -8 0 -4 16
-14 35 -21 19 -7 35 -16 35 -21 0 -5 15 -12 34 -16 19 -3 37 -12 39 -19 3 -6
21 -15 41 -18 20 -3 43 -13 52 -21 8 -9 28 -16 44 -16 16 0 35 -6 42 -13 15
-13 27 -17 73 -25 17 -3 32 -8 35 -13 3 -4 34 -12 69 -18 36 -6 67 -14 70 -19
11 -17 105 -21 125 -6 15 10 16 14 5 15 -54 4 -74 10 -81 22 -4 8 -24 18 -43
21 -19 4 -35 11 -35 15 0 5 -16 12 -35 15 -19 4 -38 13 -42 20 -5 7 -24 17
-43 22 -19 6 -34 16 -32 22 1 7 -7 13 -18 13 -36 3 -41 4 -48 15 -4 6 -22 15
-39 19 -19 3 -33 13 -33 21 0 8 -9 14 -20 14 -11 0 -20 6 -20 13 0 7 -15 19
-34 25 -19 7 -37 19 -40 27 -3 8 -12 15 -21 15 -8 0 -15 4 -15 9 0 5 -9 13
-20 16 -11 4 -51 37 -88 74 -38 37 -76 70 -86 74 -9 4 -14 12 -11 18 4 6 -2
13 -14 16 -12 3 -21 9 -21 14 0 6 -15 25 -34 44 -19 18 -31 36 -28 39 3 3 0 6
-6 6 -7 0 -12 7 -12 15 0 7 -6 18 -13 22 -16 11 -67 86 -67 100 0 6 -7 13 -15
17 -8 3 -15 14 -15 25 0 11 -7 26 -15 34 -7 8 -17 28 -21 43 -4 16 -14 34 -22
40 -8 6 -15 23 -14 38 0 15 -6 30 -14 33 -7 2 -16 26 -20 51 -4 26 -13 56 -20
67 -7 11 -16 49 -20 83 -4 35 -10 58 -14 52 -13 -22 -21 125 -21 396 1 271 8
406 21 370 4 -11 11 25 18 85 6 57 16 105 21 107 6 2 11 16 11 30 0 46 12 102
20 97 5 -3 11 14 15 37 14 93 18 108 25 108 4 0 10 23 14 50 4 28 11 50 16 50
5 0 14 25 21 55 7 30 16 55 20 55 4 0 11 15 14 33 4 17 14 41 21 52 8 11 14
26 14 35 0 8 9 32 20 53 11 20 20 46 20 57 0 11 7 23 15 26 8 3 17 21 21 40 3
19 10 34 15 34 4 0 11 15 15 34 3 19 15 42 25 52 10 9 19 24 19 32 0 9 7 23
15 32 9 10 18 27 20 40 2 12 11 32 18 44 8 11 17 33 21 49 3 15 11 27 16 27 6
0 10 9 10 19 0 11 5 23 11 27 6 3 17 22 25 40 8 19 18 34 23 34 5 0 12 14 16
31 4 17 13 33 20 35 7 3 16 21 19 40 4 18 12 33 19 31 6 -1 13 8 15 21 2 12 7
22 12 22 5 0 14 16 21 35 7 19 17 35 21 35 4 0 8 6 8 13 0 7 9 23 20 34 10 12
22 33 26 47 3 14 9 24 13 21 4 -2 14 11 21 30 8 19 18 35 22 35 5 0 14 13 19
29 6 16 17 32 25 35 8 3 14 14 14 25 0 10 7 21 15 25 8 3 14 9 14 13 -1 4 1
16 6 26 6 14 10 15 15 7 5 -8 10 -1 15 19 3 17 11 31 16 31 5 0 12 8 16 18 6
17 20 35 60 76 13 13 23 31 23 40 0 9 4 16 9 16 5 0 13 9 16 20 3 11 13 20 21
20 8 0 13 3 12 8 -3 13 21 62 31 62 5 0 12 9 16 20 3 11 11 19 17 17 6 -1 15
12 21 28 5 17 16 34 23 38 8 4 14 15 14 22 0 8 7 15 15 15 8 0 15 7 15 15 0 9
7 18 15 21 8 4 15 12 15 19 0 8 8 16 18 19 9 3 23 18 29 33 7 16 26 43 43 61
16 18 30 38 30 43 0 6 5 7 10 4 6 -3 14 5 20 19 5 14 14 26 20 26 5 0 10 6 10
13 0 6 12 23 25 36 14 13 25 28 25 35 0 6 4 5 9 -3 7 -10 11 -6 15 17 4 18 15
32 27 35 10 3 19 12 19 21 0 9 7 16 15 16 8 0 15 9 15 20 0 13 7 20 20 20 13
0 20 7 20 20 0 11 7 20 15 20 8 0 15 7 15 15 0 7 8 19 18 24 9 6 14 11 10 11
-7 0 15 22 65 66 24 22 57 64 57 74 0 6 4 10 10 10 11 0 170 161 170 172 0 4
5 8 11 8 15 0 39 26 32 34 -4 3 -2 6 3 6 5 0 22 11 38 25 16 14 34 25 40 25 7
0 6 4 -2 8 -9 6 -5 12 15 23 15 8 63 51 108 97 45 45 89 82 98 82 10 0 17 7
17 15 0 8 4 15 9 15 12 0 61 47 61 60 0 5 6 10 13 10 6 0 23 12 36 25 13 14
29 25 37 25 8 0 14 7 14 15 0 8 7 15 15 15 9 0 18 7 21 15 4 8 12 15 20 15 7
0 18 9 24 20 6 11 15 20 20 20 6 0 19 8 29 18 57 52 70 62 80 62 6 0 11 7 11
15 0 8 7 15 15 15 8 0 24 9 35 20 11 11 27 20 35 20 8 0 15 7 15 15 0 8 6 15
13 15 8 0 24 9 37 20 13 11 27 20 32 20 4 0 8 6 8 14 0 8 13 17 29 21 16 3 32
13 35 21 3 8 12 14 21 14 8 0 15 6 15 14 0 7 8 16 18 20 21 7 73 37 92 54 57
48 77 62 93 62 10 0 15 5 12 10 -3 6 0 10 8 10 8 0 26 11 41 25 15 14 33 25
40 25 8 0 16 6 19 14 3 8 19 17 36 21 17 4 31 11 31 16 0 5 8 13 18 16 9 4 28
15 41 25 13 10 34 18 47 18 13 0 24 4 24 9 0 5 11 11 25 13 37 8 51 -24 58
-131 6 -83 10 -96 33 -117 14 -13 29 -24 35 -24 8 0 14 23 10 43 -1 5 8 11 19
14 14 4 20 14 20 34 0 15 7 32 15 36 10 6 17 28 19 58 3 36 9 51 20 53 11 2
16 14 16 36 0 18 3 36 7 39 12 12 33 -15 33 -43 0 -16 7 -30 16 -34 19 -7 16
-102 -3 -98 -9 1 -13 -14 -13 -50 0 -67 -9 -98 -26 -98 -8 0 -14 -9 -14 -20 0
-11 -4 -20 -10 -20 -5 0 -10 -6 -10 -14 0 -9 17 -16 50 -21 28 -4 48 -10 45
-15 -5 -8 42 -20 81 -20 15 0 24 -6 24 -15 0 -9 6 -15 13 -14 25 5 96 -13 100
-25 3 -7 26 -16 52 -19 26 -2 51 -11 56 -18 9 -15 12 -16 55 -18 14 0 23 -5
20 -10 -7 -11 17 -21 49 -21 13 0 30 -6 38 -14 8 -8 31 -18 51 -22 21 -3 40
-13 43 -20 3 -8 17 -14 32 -14 15 0 34 -9 44 -20 9 -11 27 -21 39 -22 13 0 29
-2 36 -2 7 -1 16 -6 20 -13 4 -6 21 -15 37 -19 16 -4 35 -13 42 -19 6 -7 35
-15 63 -19 28 -4 48 -11 45 -16 -3 -5 10 -12 29 -16 20 -3 40 -12 47 -20 6 -8
20 -14 30 -14 9 0 25 -7 33 -16 9 -8 16 -11 16 -5 0 6 9 11 21 11 14 0 24 -10
32 -30 9 -25 17 -30 44 -30 27 0 34 5 39 26 4 14 9 22 13 16 6 -9 45 -32 55
-32 15 0 4 18 -14 23 -15 4 -19 11 -16 29 3 12 5 27 5 32 1 4 7 6 14 3 14 -5
67 43 67 62 0 13 35 32 44 24 3 -3 6 1 6 10 0 10 -6 17 -12 17 -8 0 -5 7 6 15
11 8 17 15 13 15 -4 0 2 9 12 19 31 31 15 75 -35 91 -19 7 -32 16 -29 21 4 5
-1 9 -10 9 -8 0 -18 6 -21 14 -3 8 -17 17 -32 21 -34 9 -54 24 -45 38 3 6 1 7
-6 3 -14 -9 -83 12 -76 24 3 4 -2 10 -10 14 -8 3 -15 12 -15 20 0 8 -15 16
-35 20 -19 4 -35 11 -35 16 0 5 -16 12 -35 16 -19 3 -35 10 -35 14 0 5 -16 14
-35 21 -19 7 -35 16 -35 20 0 4 -20 11 -45 14 -24 4 -49 13 -55 20 -6 8 -26
17 -43 21 -17 3 -37 14 -44 23 -9 12 -12 13 -13 2 0 -9 -4 -10 -12 -4 -7 6
-26 13 -41 17 -15 4 -25 11 -22 16 3 6 -2 10 -12 10 -32 0 -94 21 -88 29 6 10
-29 21 -67 21 -16 0 -28 4 -28 9 0 5 -28 14 -62 20 -34 6 -64 15 -67 20 -4 5
-34 13 -68 16 -46 6 -60 11 -56 21 4 10 -5 14 -36 15 -47 2 -39 0 -110 22 -30
9 -65 17 -77 17 -17 0 -24 8 -29 29 -8 37 3 81 21 81 8 0 17 7 20 15 4 8 11
15 16 15 6 0 29 9 52 19 23 11 49 22 59 25 10 3 16 10 13 15 -3 5 10 12 29 15
20 4 35 13 35 21 0 8 16 16 40 20 25 4 40 12 40 21 0 8 8 14 18 14 10 0 27 7
38 15 10 8 26 15 35 15 9 0 24 9 33 19 10 10 32 22 51 25 18 4 36 13 39 21 3
9 18 15 36 15 18 0 29 4 25 10 -3 5 15 15 40 21 26 7 45 17 45 25 0 13 10 16
65 16 73 0 85 -3 85 -17 0 -9 7 -18 15 -21 8 -4 15 -12 15 -20 0 -8 7 -14 16
-14 9 0 18 -9 21 -20 3 -11 14 -20 24 -20 11 0 19 -6 19 -14 0 -8 9 -18 20
-21 11 -3 20 -11 20 -16 0 -12 37 -49 50 -49 6 0 10 -9 10 -20 0 -13 7 -20 19
-20 11 0 23 -5 26 -10 4 -6 11 -8 16 -5 5 4 9 -1 9 -9 0 -8 6 -19 13 -23 7 -4
21 -19 30 -33 9 -13 17 -21 17 -17 0 4 13 -5 28 -21 15 -15 35 -31 45 -35 9
-3 17 -14 17 -22 0 -9 4 -14 8 -11 10 6 62 -47 62 -63 0 -6 6 -11 14 -11 8 0
17 -13 21 -30 4 -16 11 -30 16 -30 6 0 7 5 4 10 -3 6 -1 10 4 10 6 0 11 -7 11
-15 0 -8 5 -15 11 -15 13 0 59 -46 59 -59 0 -5 9 -11 21 -14 12 -3 18 -10 14
-16 -3 -6 5 -15 20 -21 14 -5 23 -14 20 -20 -4 -6 5 -10 21 -10 26 0 65 -32
77 -64 4 -9 13 -16 21 -16 8 0 23 -16 35 -35 23 -40 61 -49 61 -15 0 11 3 19
8 19 20 -3 32 3 32 16 0 9 8 19 18 22 9 4 28 20 42 35 13 15 35 28 48 28 13 0
25 7 28 18 3 9 9 24 14 33 5 11 2 19 -11 27 -10 7 -25 27 -33 46 -8 19 -28 44
-45 57 -17 12 -31 32 -31 43 0 37 -12 66 -26 66 -8 0 -14 9 -14 20 0 11 -7 20
-15 20 -8 0 -15 8 -15 18 0 10 -9 25 -20 35 -11 9 -20 24 -20 32 0 8 -7 15
-15 15 -9 0 -15 9 -15 25 0 14 -8 33 -18 43 -10 10 -21 26 -25 35 -3 9 -13 17
-22 17 -8 0 -15 9 -15 20 0 11 -5 20 -11 20 -5 0 -8 4 -5 9 3 5 -1 12 -10 15
-9 4 -14 9 -11 12 3 3 -6 22 -19 41 -13 20 -24 40 -24 45 0 5 -4 6 -10 3 -5
-3 -12 5 -16 19 -3 13 -15 26 -26 29 -11 3 -17 10 -13 16 3 6 0 14 -9 17 -8 3
-17 18 -21 33 -3 14 -21 40 -40 56 -42 34 -50 89 -15 98 11 3 19 11 18 18 -2
8 11 15 32 19 19 3 45 12 57 18 12 7 34 12 48 12 15 0 38 9 52 20 14 11 39 20
56 20 16 0 35 7 42 16 6 9 32 18 60 22 27 3 55 11 62 17 6 7 35 15 64 19 28 4
58 14 66 22 8 7 30 14 48 14 56 0 65 18 65 133 0 133 -4 146 -42 145 -16 -1
-32 -7 -35 -13 -2 -7 -27 -16 -55 -19 -28 -4 -60 -14 -72 -21 -11 -8 -42 -18
-68 -21 -26 -4 -49 -13 -52 -20 -3 -9 -20 -14 -43 -14 -21 0 -48 -6 -59 -14
-19 -14 -53 -23 -94 -25 -13 -1 -13 -3 -1 -10 11 -7 4 -11 -25 -17 -21 -4 -55
-15 -74 -25 -19 -11 -49 -19 -67 -19 -23 0 -33 -5 -33 -15 0 -8 -8 -15 -17
-15 -10 0 -31 -7 -46 -15 -47 -24 -117 -24 -117 0 0 8 -9 15 -20 15 -13 0 -20
7 -20 20 0 11 -7 20 -15 20 -8 0 -17 14 -21 35 -3 20 -12 35 -20 35 -8 0 -14
7 -14 15 0 8 -7 15 -16 15 -9 0 -18 9 -21 20 -3 11 -11 20 -18 20 -7 0 -16 16
-19 35 -4 20 -11 33 -17 29 -6 -3 -13 3 -16 14 -2 11 -13 22 -24 25 -10 3 -19
12 -19 21 0 9 -6 16 -14 16 -8 0 -17 15 -20 34 -4 19 -13 37 -21 40 -8 3 -15
12 -15 21 0 8 -7 15 -15 15 -8 0 -15 6 -15 14 0 8 -5 18 -10 21 -14 9 -52 -4
-45 -16 4 -5 2 -9 -4 -9 -6 0 -13 16 -17 35 -3 19 -10 35 -15 35 -5 0 -9 3 -8
8 1 4 -2 17 -6 28 -10 31 -80 41 -180 27z m205 -195 c0 -5 -7 -8 -15 -8 -17 0
-18 2 -9 25 5 13 8 14 15 3 5 -7 9 -16 9 -20z m-32 -40 c-5 -15 -23 -11 -26 6
-2 11 2 14 13 10 9 -4 14 -11 13 -16z"/>
<path d="M8538 11741 c-1 -6 -104 -15 -228 -21 -130 -6 -226 -14 -228 -20 -2
-6 -19 -10 -38 -10 -24 0 -34 -5 -34 -15 0 -9 -7 -18 -15 -21 -12 -5 -15 -28
-15 -116 l0 -109 88 7 c48 4 89 10 92 15 5 7 271 29 355 29 20 0 26 3 17 9
-19 12 251 21 442 14 91 -3 163 -10 160 -15 -3 -4 6 -8 20 -8 62 0 282 -23
286 -30 3 -4 43 -11 90 -14 47 -4 87 -11 90 -16 3 -5 30 -12 60 -16 30 -3 62
-12 70 -19 8 -7 38 -17 65 -21 28 -4 59 -13 70 -20 11 -6 39 -15 63 -19 23 -4
42 -10 42 -15 0 -4 21 -13 47 -20 26 -6 50 -15 53 -19 3 -5 22 -11 43 -15 33
-6 37 -5 37 14 0 11 -7 20 -15 20 -9 0 -18 7 -21 15 -3 8 -14 17 -25 20 -11 4
-17 10 -15 14 3 4 -10 13 -29 19 -18 7 -36 19 -39 27 -3 8 -13 15 -21 15 -9 0
-23 7 -31 15 -9 8 -24 15 -35 15 -11 0 -22 7 -25 15 -3 8 -21 17 -40 21 -19 3
-34 12 -34 20 0 8 -8 14 -18 14 -9 0 -33 8 -52 19 -19 10 -52 21 -72 25 -21 4
-38 11 -38 15 0 5 -14 11 -31 15 -17 3 -45 12 -62 20 -18 8 -50 17 -71 20 -22
3 -42 10 -45 15 -4 5 -38 15 -76 21 -38 6 -72 15 -75 20 -3 4 -39 12 -80 15
-41 4 -83 12 -93 17 -10 5 -107 14 -215 19 -108 5 -201 14 -206 19 -13 13
-163 13 -168 1z"/>
<path d="M10177 11246 c-4 -9 3 -16 18 -18 20 -4 22 -3 12 14 -14 22 -23 23
-30 4z"/>
<path d="M7500 10130 c-12 -8 -9 -10 13 -10 16 0 26 4 22 10 -8 12 -16 12 -35
0z"/>
<path d="M7419 10088 c-2 -23 2 -43 13 -53 6 -7 7 0 3 20 -5 28 -5 28 5 6 12
-28 34 -16 28 15 -4 22 -47 32 -49 12z"/>
<path d="M7534 10071 c3 -18 6 -37 6 -42 0 -5 7 -6 15 -3 9 4 15 18 14 38 -1
20 -3 24 -6 12 -5 -19 -6 -18 -20 5 -14 24 -14 24 -9 -10z"/>
<path d="M8380 9920 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0
-4 -4 -4 -10z"/>
<path d="M8400 9874 c0 -15 -2 -16 -9 -5 -13 20 -25 -48 -18 -97 l5 -37 -13
30 c-15 33 -16 33 -21 -10 -2 -16 -10 -35 -19 -41 -18 -14 -30 -64 -15 -64 5
0 10 -5 10 -11 0 -5 -4 -8 -8 -5 -5 3 -21 -22 -36 -55 -16 -36 -31 -57 -38
-53 -8 5 -9 -3 -5 -24 7 -33 -1 -40 -20 -21 -9 9 -11 7 -6 -9 4 -16 -1 -24
-21 -33 -26 -12 -30 -20 -26 -48 1 -9 -4 -12 -14 -8 -10 4 -16 1 -16 -7 0 -7
-9 -21 -20 -31 -26 -24 -25 -35 4 -35 26 0 39 20 30 45 -4 8 -2 15 4 15 12 0
22 -37 15 -56 -3 -8 -11 -14 -19 -14 -8 0 -14 -4 -14 -10 0 -5 -18 -10 -40
-10 -36 0 -40 -2 -40 -26 0 -22 -2 -25 -15 -14 -9 8 -15 8 -15 2 0 -19 12 -42
22 -42 18 0 38 -29 38 -55 0 -19 5 -26 18 -24 55 5 62 1 62 -41 0 -28 4 -40
14 -40 10 0 16 -13 18 -37 3 -37 4 -38 48 -41 55 -4 80 -25 80 -68 0 -43 49
-94 91 -94 27 0 29 -2 29 -40 0 -33 3 -40 20 -40 17 0 20 -7 20 -40 l0 -40 40
0 c36 0 40 -2 40 -26 0 -33 21 -54 54 -54 24 0 26 -4 26 -40 l0 -40 63 0 c83
0 97 -14 97 -98 l0 -62 59 0 59 0 7 -76 c4 -50 11 -77 21 -81 8 -3 14 -11 14
-19 0 -10 12 -14 40 -14 36 0 40 -2 40 -25 0 -14 4 -25 9 -25 12 0 71 -59 71
-71 0 -5 7 -9 15 -9 11 0 15 -11 15 -40 0 -36 3 -40 25 -40 23 0 25 -4 25 -40
l0 -40 40 0 40 0 0 -40 0 -40 40 0 40 0 0 -40 0 -40 40 0 40 0 0 -40 0 -40 40
0 40 0 0 -40 c0 -35 3 -40 23 -40 32 0 57 -30 57 -69 0 -39 47 -84 94 -89 48
-6 66 -28 66 -84 l0 -48 40 0 c29 0 40 -4 40 -15 0 -9 9 -15 23 -15 24 -1 77
-42 77 -60 0 -6 -4 -8 -10 -5 -6 4 -10 -7 -10 -24 0 -18 5 -31 13 -31 17 1 95
38 80 39 -7 1 -13 12 -13 25 0 27 16 44 22 24 3 -7 12 -3 25 11 12 13 26 20
31 17 11 -6 13 33 5 112 -3 36 -9 52 -19 52 -10 0 -14 13 -14 51 0 41 -3 50
-15 45 -18 -7 -30 32 -20 65 5 14 4 19 -4 15 -7 -5 -11 4 -11 24 0 17 -9 40
-20 52 -17 19 -20 19 -20 5 0 -9 -4 -17 -8 -17 -5 0 -9 34 -10 75 0 73 -2 77
-39 120 -21 23 -44 44 -51 44 -10 1 -10 5 2 17 18 17 21 28 6 19 -9 -5 -13 9
-13 41 0 6 -13 8 -30 6 -29 -5 -30 -4 -23 31 6 30 3 38 -14 47 -11 6 -20 15
-20 21 0 6 5 7 12 3 8 -5 9 0 5 17 -4 13 -7 29 -7 36 0 6 -9 9 -20 6 -18 -5
-20 0 -20 36 0 31 -4 41 -16 41 -11 0 -15 6 -11 20 3 11 -1 27 -10 35 -9 9
-12 24 -8 37 5 18 4 20 -5 8 -9 -13 -12 -12 -26 5 -8 11 -13 30 -11 42 3 16
-3 27 -19 38 -13 9 -24 24 -24 35 0 11 -6 20 -12 20 -13 0 -38 44 -38 67 0 7
-3 12 -7 12 -19 -3 -47 3 -35 8 17 7 16 73 -1 73 -22 0 -70 57 -64 74 4 9 1
16 -6 16 -23 0 -38 17 -41 44 -2 14 2 26 8 27 6 1 0 4 -13 8 -16 4 -25 15 -27
34 -3 27 -25 57 -42 57 -15 0 -44 38 -37 49 3 6 0 11 -7 11 -7 0 -18 10 -23
23 -6 12 -20 26 -32 32 -12 5 -24 21 -27 36 -6 31 -33 62 -47 54 -5 -4 -9 -2
-9 4 0 5 6 11 13 14 6 2 1 2 -12 0 -21 -4 -23 -1 -18 23 6 34 -1 54 -19 54 -8
0 -14 4 -14 9 0 18 -52 71 -70 71 -12 0 -17 7 -16 23 1 17 -4 21 -21 19 -19
-3 -23 2 -23 22 0 14 5 26 10 26 6 0 10 4 10 9 0 5 -9 7 -19 4 -14 -4 -24 3
-35 24 -19 37 -72 93 -87 93 -6 0 -8 7 -5 16 4 12 1 14 -14 9 -18 -6 -20 -2
-20 34 0 37 -3 41 -24 41 -31 0 -56 25 -56 56 0 21 -4 24 -42 25 -39 1 -40 2
-13 8 43 9 15 24 -30 15 -38 -7 -43 -1 -21 22 10 10 16 10 18 2 3 -8 9 -8 23
-1 17 10 15 12 -15 28 -25 13 -35 14 -38 6 -5 -15 -35 26 -37 49 -2 18 -35 60
-48 60 -5 0 -7 -7 -3 -15 3 -9 0 -15 -9 -15 -9 0 -12 6 -9 15 4 8 1 22 -6 30
-7 8 -10 21 -7 29 4 10 1 13 -9 9 -9 -3 -27 6 -45 23 -26 25 -29 26 -29 8z
m23 -71 c3 -5 1 -17 -4 -28 l-8 -20 -1 20 -1 20 -9 -20 c-8 -18 -9 -17 -9 8
-1 25 21 39 32 20z m30 -36 c-9 -16 -24 9 -17 28 7 18 8 18 14 -1 4 -10 5 -23
3 -27z m67 -37 c0 -5 -9 -10 -20 -10 -11 0 -20 5 -20 10 0 6 9 10 20 10 11 0
20 -4 20 -10z m-20 -80 c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10 10
10 6 0 10 -4 10 -10z m-183 -52 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12
1 -19z m-23 -110 c7 -11 5 -20 -6 -31 -20 -20 -28 -12 -28 26 0 30 16 33 34 5z
m554 -105 c3 -17 -2 -23 -16 -23 -11 0 -23 -6 -26 -12 -3 -7 -4 4 -3 25 1 32
4 37 22 35 12 -2 21 -12 23 -25z m-771 -145 c-3 -7 -5 -2 -5 12 0 14 2 19 5
13 2 -7 2 -19 0 -25z m906 -44 c-9 -23 -23 -16 -23 12 0 22 2 24 14 14 8 -6
12 -18 9 -26z m232 -304 c3 -5 1 -10 -4 -10 -6 0 -11 5 -11 10 0 6 2 10 4 10
3 0 8 -4 11 -10z m712 -1202 c-3 -7 -5 -2 -5 12 0 14 2 19 5 13 2 -7 2 -19 0
-25z"/>
<path d="M7008 9705 c2 -15 11 -25 22 -25 13 0 16 6 12 25 -2 15 -11 25 -22
25 -13 0 -16 -6 -12 -25z"/>
<path d="M6910 9691 c0 -4 11 -6 25 -3 14 2 25 6 25 8 0 2 -11 4 -25 4 -14 0
-25 -4 -25 -9z"/>
<path d="M6969 9668 c-1 -2 -3 -16 -4 -33 -2 -16 -1 -24 2 -17 2 6 11 12 19
12 8 0 14 9 14 20 0 11 -7 20 -15 20 -8 0 -16 -1 -16 -2z"/>
<path d="M7012 9618 c7 -19 45 -21 51 -4 5 12 -1 16 -24 16 -20 0 -29 -4 -27
-12z"/>
<path d="M7058 9593 c-24 -6 -23 -23 1 -23 10 0 22 -8 26 -17 6 -17 7 -17 17
0 19 35 1 52 -44 40z"/>
<path d="M7049 9518 c-6 -21 -5 -39 2 -44 5 -3 9 6 9 20 0 21 -7 36 -11 24z"/>
<path d="M6975 9410 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"/>
<path d="M8060 9300 c-13 -8 -12 -10 3 -10 9 0 17 5 17 10 0 12 -1 12 -20 0z"/>
<path d="M6840 9265 c0 -8 2 -15 4 -15 2 0 6 7 10 15 3 8 1 15 -4 15 -6 0 -10
-7 -10 -15z"/>
<path d="M7981 9214 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M6691 9201 c-12 -21 -5 -31 19 -31 13 0 16 6 12 25 -5 30 -17 32 -31
6z"/>
<path d="M7900 9119 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"/>
<path d="M7830 9075 c0 -8 7 -15 15 -15 8 0 15 4 15 9 0 5 -7 11 -15 15 -9 3
-15 0 -15 -9z"/>
<path d="M7984 5575 c5 -18 1 -27 -14 -35 -26 -14 -26 -27 2 -25 12 1 25 11
29 22 9 25 1 63 -13 63 -6 0 -7 -11 -4 -25z"/>
<path d="M4564 5546 c-3 -8 -1 -23 5 -33 9 -15 10 -13 11 15 0 34 -7 42 -16
18z"/>
<path d="M7976 5443 c6 -4 14 -15 17 -23 5 -11 6 -9 4 4 -1 11 -9 21 -17 23
-10 3 -11 2 -4 -4z"/>
<path d="M4571 5404 c-1 -38 -3 -41 -26 -39 -33 4 -40 -10 -21 -40 11 -17 26
-25 46 -25 36 0 45 13 29 42 -7 13 -16 41 -20 63 l-6 40 -2 -41z"/>
<path d="M4540 5410 c-13 -8 -12 -10 3 -10 9 0 17 5 17 10 0 12 -1 12 -20 0z"/>
<path d="M4542 5251 c0 -35 1 -35 11 -11 6 14 15 28 20 31 5 3 0 9 -11 11 -17
5 -20 1 -20 -31z"/>
<path d="M6182 5131 c2 -17 0 -32 -6 -35 -6 -2 -3 -7 8 -11 22 -9 33 6 32 43
-1 18 -7 28 -20 30 -16 3 -18 -1 -14 -27z"/>
<path d="M6145 5101 c-3 -5 -1 -12 5 -16 5 -3 10 1 10 9 0 18 -6 21 -15 7z"/>
<path d="M7990 3960 c0 -25 17 -35 35 -20 18 15 12 26 -15 33 -15 4 -20 0 -20
-13z"/>
<path d="M4518 3928 c9 -9 15 -9 24 0 9 9 7 12 -12 12 -19 0 -21 -3 -12 -12z"/>
<path d="M4480 3905 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"/>
<path d="M4531 3905 c2 -5 11 -27 19 -47 16 -43 38 -50 38 -13 0 14 -5 25 -11
25 -5 0 -19 10 -30 23 -11 12 -18 18 -16 12z"/>
<path d="M4610 3860 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0
-4 -4 -4 -10z"/>
<path d="M7920 3820 c0 -5 -9 -10 -19 -10 -26 0 -35 -16 -15 -24 29 -11 39 -6
40 19 1 14 0 25 -2 25 -2 0 -4 -4 -4 -10z"/>
<path d="M4685 3810 c-3 -5 3 -10 14 -10 12 0 21 -5 21 -11 0 -8 5 -7 15 1 9
7 14 15 11 17 -9 10 -55 12 -61 3z"/>
<path d="M4880 3759 c0 -5 11 -9 25 -9 14 0 25 2 25 4 0 2 -11 6 -25 8 -14 3
-25 1 -25 -3z"/>
<path d="M7586 3761 c-3 -5 1 -11 9 -14 9 -4 12 -1 8 9 -6 16 -10 17 -17 5z"/>
<path d="M7910 3750 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"/>
<path d="M2550 835 l0 -525 100 0 100 0 0 525 0 525 -100 0 -100 0 0 -525z"/>
<path d="M4714 1341 c-45 -28 -64 -59 -64 -108 0 -94 109 -153 188 -103 65 41
78 133 25 186 -47 47 -99 56 -149 25z"/>
<path d="M5536 1352 c-2 -4 -4 -105 -4 -223 0 -117 -3 -213 -6 -212 -4 1 -21
14 -39 27 -144 110 -329 53 -414 -129 -25 -54 -28 -71 -28 -170 0 -98 3 -116
26 -166 56 -118 139 -179 246 -179 75 0 120 18 174 70 23 22 44 40 46 40 1 0
3 -22 3 -50 l0 -50 100 0 100 0 0 525 0 525 -99 0 c-55 0 -102 -4 -105 -8z
m-65 -579 c114 -96 50 -293 -95 -293 -31 0 -45 7 -76 38 -67 67 -67 172 -1
242 34 36 43 40 89 40 40 0 57 -5 83 -27z"/>
<path d="M6840 1208 c-18 -7 -20 -17 -20 -118 l0 -110 -35 0 -35 0 0 -90 0
-90 35 0 35 0 2 -242 3 -243 103 -3 102 -3 0 246 0 245 45 0 45 0 0 89 0 90
-42 3 -43 3 -5 115 -5 114 -83 1 c-46 0 -92 -3 -102 -7z"/>
<path d="M1917 990 c-59 -15 -108 -44 -155 -91 -135 -137 -134 -376 3 -513 74
-73 122 -91 245 -91 91 0 112 4 155 25 198 97 257 363 121 547 -62 85 -142
126 -251 129 -44 2 -97 -1 -118 -6z m172 -198 c49 -36 72 -90 68 -159 -2 -52
-8 -69 -33 -100 -67 -82 -168 -80 -228 4 -69 98 -33 233 74 276 26 11 92 -1
119 -21z"/>
<path d="M3116 984 c-70 -22 -155 -109 -185 -188 -31 -83 -37 -167 -17 -244
42 -163 138 -253 275 -253 70 -1 121 22 171 77 l35 39 3 -53 3 -52 100 0 99 0
0 335 0 335 -100 0 -100 0 0 -40 0 -41 -55 42 c-79 59 -138 70 -229 43z m190
-200 c103 -49 119 -181 33 -267 -38 -38 -41 -39 -96 -35 -51 4 -61 9 -89 43
-68 77 -43 212 47 257 42 22 62 22 105 2z"/>
<path d="M6109 987 c-96 -36 -171 -116 -204 -215 -34 -105 -17 -252 38 -338
59 -93 129 -134 226 -134 75 0 104 12 164 69 l47 45 0 -52 0 -52 105 0 105 0
0 335 0 335 -105 0 -105 0 0 -42 0 -41 -22 20 c-78 70 -175 98 -249 70z m208
-223 c63 -48 79 -124 43 -198 -25 -53 -74 -86 -126 -86 -51 0 -83 17 -112 60
-33 48 -29 148 8 199 48 66 121 76 187 25z"/>
<path d="M7420 991 c-80 -26 -152 -92 -196 -178 -27 -54 -29 -67 -29 -168 0
-98 3 -116 26 -166 56 -118 140 -179 249 -179 75 0 113 16 167 69 23 22 44 41
48 41 4 0 5 -23 3 -50 l-3 -50 103 0 102 0 0 335 0 335 -105 0 -105 0 6 -25
c3 -14 3 -32 0 -42 -6 -14 -11 -12 -39 14 -18 17 -47 38 -66 48 -39 19 -125
28 -161 16z m195 -219 c62 -47 84 -144 47 -212 -57 -104 -197 -108 -243 -7
-21 48 -24 90 -8 138 14 41 64 96 93 102 33 7 87 -3 111 -21z"/>
<path d="M8715 976 c-80 -25 -143 -75 -187 -146 -53 -88 -62 -236 -20 -333 72
-162 263 -244 432 -185 l65 23 3 89 c2 49 -1 93 -5 97 -5 5 -26 2 -48 -6 -22
-8 -67 -14 -100 -15 -102 0 -155 50 -155 148 0 122 109 181 247 133 29 -10 55
-16 58 -13 12 12 5 170 -8 180 -43 36 -206 52 -282 28z"/>
<path d="M9385 974 c-266 -85 -316 -456 -84 -627 79 -59 244 -72 343 -28 59
26 136 99 164 155 50 99 50 231 2 331 -28 57 -99 128 -158 156 -62 29 -194 36
-267 13z m206 -200 c44 -42 59 -76 59 -134 0 -58 -15 -92 -60 -135 -27 -26
-38 -30 -90 -30 -51 0 -63 4 -91 30 -95 90 -74 242 41 297 8 4 37 6 63 4 36
-2 55 -10 78 -32z"/>
<path d="M10360 965 c-25 -13 -58 -37 -74 -54 -17 -17 -33 -31 -37 -31 -4 0
-9 21 -11 48 l-3 47 -97 3 -98 3 0 -341 0 -341 98 3 97 3 2 204 c2 198 3 205
27 241 46 70 134 79 178 17 22 -30 23 -43 28 -247 l5 -215 95 0 95 0 5 211 c5
214 6 217 45 260 42 45 106 45 151 0 l34 -34 0 -205 c0 -113 3 -212 6 -221 5
-13 24 -16 101 -16 l94 0 -3 262 c-3 247 -4 265 -25 303 -82 153 -239 165
-377 28 -28 -27 -51 -43 -53 -36 -2 6 -18 30 -34 53 -57 78 -162 101 -249 55z"/>
<path d="M3760 975 c0 -3 68 -142 152 -309 l152 -304 -72 -169 c-40 -92 -72
-172 -72 -176 0 -4 46 -6 102 -5 l101 3 134 310 c73 171 163 380 199 465 37
85 71 163 76 173 8 17 2 18 -97 15 l-107 -3 -54 -125 c-30 -69 -67 -153 -81
-187 -14 -35 -29 -60 -35 -57 -5 3 -50 89 -99 190 l-90 184 -104 0 c-58 0
-105 -2 -105 -5z"/>
<path d="M4670 645 l0 -335 105 0 105 0 0 335 0 335 -105 0 -105 0 0 -335z"/>
<path d="M8114 506 c-30 -30 -34 -40 -34 -86 0 -46 4 -56 34 -86 30 -30 41
-34 84 -34 120 0 172 138 81 214 -24 20 -41 26 -81 26 -44 0 -54 -4 -84 -34z"/>
</g>
</svg>

    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>
}
